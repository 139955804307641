<template>
  <div class="d-flex flex-column flex-md-row px-5">
    <CardContainer>
      <div
        class="position-sticky bg-white pt-3 px-10 d-flex flex-row align-items-center justify-content-between"
        style="top: 0px; z-index: 50;"
      >
        <h4 class="mb-0">{{ $tc("ADVANCED_SETTINGS.DOMAIN.TITLE") }}</h4>
        <v-btn
          v-if="isAdmin"
          icon
          color="#3699ff"
          :to="{ name: 'settings_domains_create' }"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>

      <div v-if="isAdmin" class="px-10 mt-2">
        <b-form-input
          placeholder="Enter a domain"
          @input="searchDomains"
        ></b-form-input>
      </div>

      <div
        class="card-body d-flex flex-row flex-md-column flex-wrap flex-md-nowrap px-7"
      >
        <template v-if="domains.length > 0">
          <div
            v-for="(item, index) in domains"
            :key="`domain_${index}`"
            class="inline"
          >
            <router-link
              :to="{ name: 'settings_domain_edit', params: { id: item.id } }"
              class="card-domains d-flex rounded flex-row align-items-center mb-2 p-2"
              :class="{ 'domain-active': $route.params.id === item.id }"
            >
              <v-icon>mdi-link</v-icon>
              <span class="font-weight-bold ml-2">{{ item.host }}</span>
              <a
                href="#"
                title="To AudioHub"
                v-on:click.stop.prevent="redirectToAudioHub(item.host)"
              >
                <v-icon small class="mx-auto pl-3">mdi-open-in-new</v-icon>
              </a>
            </router-link>
          </div>
        </template>

        <div v-if="isLoadingDomain" class="mt-2 w-100">
          <div
            v-for="index in 5"
            :key="`skeleton_domais_${index}`"
            class="d-flex felx-row align-items-center p-2"
          >
            <b-skeleton-img
              type="avatar"
              width="24px"
              height="24px"
            ></b-skeleton-img>
            <b-skeleton class="w-100 ml-2"></b-skeleton>
          </div>
        </div>

        <div
          v-if="needPaginations"
          class="d-flex justify-content-center"
          @click="changePage"
        >
          <span style="cursor: pointer;"><u>Load more</u></span>
        </div>

        <div
          v-if="domains.length === 0 && !isLoadingDomain"
          class="w-100 d-flex justify-content-center"
        >
          <span class="font-weight-bold">{{
            $tc("ADVANCED_SETTINGS.DOMAIN.NO_DOMAIN_CREATE")
          }}</span>
        </div>
      </div>
    </CardContainer>

    <Domains
      class="card pt-3"
      :company="getCompanyUser"
      @reloadDomain="loadDomains"
      :domains="domains"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardContainer from "@/components/settings/CardContainer";
import Domains from "@/components/settings/Domains";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { getAllDomainsPremiumV2 } from "@/api/domains/getters.js";

export default {
  components: {
    CardContainer,
    Domains
  },

  data() {
    return {
      domains: [],
      isLoadingDomain: true,
      searchTimeout: {},
      lastPages: 0,
      filters: {
        page: 1,
        limit: 10
      }
    };
  },

  mounted() {
    this.loadDomains();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Settings",
        pageCategory: "Domains"
      }
    ]);
  },

  computed: {
    ...mapGetters(["currentUser", "isAdmin"]),

    getCompanyUser() {
      return this.currentUser.company;
    },

    needPaginations() {
      return this.filters.page < this.lastPages;
    }
  },

  methods: {
    async loadDomains(forceRefresh = false) {
      this.isLoadingDomain = true;

      if (forceRefresh) {
        this.domains = [];
        this.filters.page = 1;
      }

      try {
        const response = await getAllDomainsPremiumV2(this.filters, this.axios);
        this.domains = this.domains.concat(response.data);
        this.lastPages = response.last_page;
      } catch (error) {
        console.error("---ERROR-DOMAINS---");
        console.error(error);
      }

      this.isLoadingDomain = false;
    },

    changePage() {
      this.filters.page += 1;
      this.loadDomains();
    },

    searchDomains(value) {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        if (value.length > 0) {
          this.filters.search = value;
        } else {
          delete this.filters.search;
        }
        this.loadDomains(true);
      }, 1000);
    },

    redirectToAudioHub(host) {
      let protocol = /\.local\./g.test(host) ? "http://" : "https://";

      window.open(`${protocol}${host}`);
    }
  }
};
</script>

<style scoped>
.card-domains:hover,
.domain-active {
  background: #f8f9fa;
  color: #3699ff;
  cursor: pointer;
}

.container-podcast {
  width: 100%;
  height: 605px;
}

@media (min-width: 960px) {
  .container-podcast {
    width: 25%;
  }
}
</style>
